.RootContainer {
  width: 100%;
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: space-between;
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
