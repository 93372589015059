.RootContainer {
  display: flex;
  justify-content: center;
}

.LogInContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HeaderContainer {
  align-self: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .HeaderContainer {
    align-self: center;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-align: left;
    align-items: flex-start;
  }

  .FormContainer {
    margin-top: 20px;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 0 10px 0 rgba(136, 152, 170, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px 10px;
  }
}
.FormContainer {
  margin-top: 20px;
  width: 100%;
  max-width: 450px;
  box-shadow: 0 0 10px 0 rgba(136, 152, 170, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 0px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
